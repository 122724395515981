import React, { FC, useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Chip,
  IconButton,
  useTheme,
  useMediaQuery,
  Tooltip,
  Fab,
} from '@mui/material';
import { User, UserService } from '../../api';
import UserEditPopup from './UserEditPopup';
import UserAvatar from '../UserAvatar';
import dayjs from 'dayjs';
import PunchClockIcon from '@mui/icons-material/PunchClock';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { SxProps, Theme } from '@mui/system';
import { useUser } from '../../contexts/UserContext';

const UserOverview: FC = () => {
  const { user } = useUser();
  const [userData, setUserData] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

  useEffect(() => {
    UserService.getUsers()
      .then((response) => {
        setUserData(response);
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
      });
  }, []);

  // If no user is provided (null or undefined), use a hardcoded dummy user
  const inviteUserDummy: User = {
    mail: '',
    firstName: '(Wird von Google gelesen)',
    lastName: '(Wird von Google gelesen)',
    roles: ['WERKSTUD'],
    province: 'DE-HE',
    allowedWorkingHours: {
      nightAllowed: false,
      weekendAllowed: false,
      holidayAllowed: false,
      weekMax: 20,
    },
    pendingMinutes: 0,
  };

  const handleRowClick = (user: User | null) => {
    setSelectedUser(user);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setSelectedUser(null);
  };

  const handleSaveUser = (updatedUser: User) => {
    setUserData((prevUserData) =>
      prevUserData.map((user) =>
        user.uuid === updatedUser.uuid ? updatedUser : user,
      ),
    );
  };
  const handleDeleteUser = (user: User) => {
    setUserData((prevUserData) =>
      prevUserData.filter((u) => u.uuid !== user.uuid),
    );
  };

  const startDate = dayjs().startOf('month').format('YYYY-MM-DD');
  const endDate = dayjs().endOf('month').format('YYYY-MM-DD');

  const handleOpenLink = (userUuid: string) => {
    const url = `/trackMonthly/${startDate}/${endDate}/${userUuid}`;
    window.open(url, '_blank');
  };
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const tableBodyCellSx = { padding: '16px 8px' };
  const tableHeadCellSx = {
    fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1.0rem' },
    fontWeight: 'bold',
    padding: '16px 8px',
  };
  const tableBodyTypoSx = (user: User): SxProps<Theme> => {
    return {
      fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1.0rem' },
      overflowWrap: 'break-word',
      wordBreak: 'break-word',
      color: user.status === 'DELETED' ? 'lightgrey' : '',
    };
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '10px',
        position: 'relative',
        flexDirection: 'column',
      }}
    >
      <TableContainer
        component={Paper}
        elevation={3}
        sx={{
          width: '100%',
          maxWidth: '1200px',
          borderRadius: '12px',
          overflow: 'hidden',
          padding: '16px',
        }}
      >
        <Typography variant="h5" gutterBottom align="center" fontWeight="bold">
          Mitarbeiterverwaltung
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={tableHeadCellSx}></TableCell>
              {isDesktop && <TableCell sx={tableHeadCellSx}>Name</TableCell>}
              <TableCell sx={tableHeadCellSx}>Email</TableCell>
              <TableCell sx={tableHeadCellSx}>Rollen</TableCell>
              <TableCell sx={tableHeadCellSx}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userData.map((saplingUser, index) => (
              <TableRow
                key={index}
                style={{
                  backgroundColor:
                    saplingUser.status !== 'DELETED' &&
                    (saplingUser.status === 'PENDING' ||
                      saplingUser.roles.length === 0 ||
                      (saplingUser.roles.length === 1 &&
                        saplingUser.roles[0] === 'DEFAULT'))
                      ? '#ffe9e9'
                      : '',
                }}
              >
                <TableCell sx={tableBodyCellSx}>
                  {saplingUser.status === 'DELETED' ? (
                    ''
                  ) : (
                    <UserAvatar
                      user={
                        saplingUser.status === 'INVITED'
                          ? { ...saplingUser, firstName: '+' }
                          : saplingUser
                      }
                      style={{ width: 40, height: 40 }}
                    />
                  )}
                </TableCell>
                {isDesktop && (
                  <TableCell sx={tableBodyCellSx}>
                    <Typography sx={tableBodyTypoSx(saplingUser)}>
                      {saplingUser.status === 'INVITED'
                        ? '(Eingeladen)'
                        : saplingUser.firstName + ' ' + saplingUser.lastName}
                    </Typography>
                  </TableCell>
                )}
                <TableCell sx={tableBodyCellSx}>
                  <Typography sx={tableBodyTypoSx(saplingUser)}>
                    {saplingUser.mail}
                  </Typography>
                </TableCell>
                <TableCell sx={{ padding: '16px 8px', width: '20%' }}>
                  {saplingUser.roles.map((role, j) =>
                    role !== 'DEFAULT' || saplingUser.roles.length === 1 ? (
                      <Chip
                        key={j}
                        label={role}
                        color={
                          role === 'ADMIN' ||
                          role === 'WERKSTUD' ||
                          role === 'HR' ||
                          role === 'FINANCE' ||
                          role === 'BOARD' ||
                          role === 'EMPLOYEE'
                            ? 'success'
                            : 'default'
                        }
                        sx={{
                          marginRight: '4px',
                          backgroundColor: getChipBackgroundColor(
                            saplingUser.status,
                            role,
                          ),
                          color:
                            saplingUser.status === 'DELETED' ||
                            saplingUser.status === 'INVITED'
                              ? '#888888'
                              : '#333333',
                          margin: '4px',
                          fontSize: {
                            xs: '0.7rem',
                            sm: '0.8rem',
                            md: '0.9rem',
                          },
                        }}
                      />
                    ) : (
                      ''
                    ),
                  )}
                </TableCell>
                <TableCell sx={tableBodyCellSx}>
                  {!(
                    user?.roles.includes('FINANCE') && user?.roles.length == 1
                  ) &&
                    saplingUser.status !== 'DELETED' &&
                    saplingUser.status !== 'INVITED' && (
                      <Tooltip title="Bearbeite Nutzer">
                        <IconButton
                          color="primary"
                          onClick={() => handleRowClick(saplingUser)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  {saplingUser.status !== 'INVITED' &&
                    saplingUser?.roles.find((role) => role === 'WERKSTUD') !==
                      undefined && (
                      <Tooltip title="Öffne Timesheet für diesen Nutzer">
                        <IconButton
                          color="primary"
                          onClick={() => handleOpenLink(saplingUser.uuid || '')}
                          disabled={saplingUser.status === 'DELETED'}
                        >
                          <PunchClockIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {isPopupOpen && (
        <UserEditPopup
          user={selectedUser ? selectedUser : inviteUserDummy}
          inviteMode={selectedUser === null}
          open={isPopupOpen}
          onClose={handleClosePopup}
          onSave={handleSaveUser}
          onDelete={handleDeleteUser}
        />
      )}

      {/* Floating Action Button */}
      <Fab
        variant="extended"
        color="primary"
        aria-label="invite"
        onClick={() => handleRowClick(null)}
        sx={{
          marginTop: '32px',
          marginBottom: '16px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <AddIcon />
        Einladen
      </Fab>
    </div>
  );
};

// Helper function to determine background color
const getChipBackgroundColor = (status: string | undefined, role: string) => {
  if (status !== undefined && (status === 'DELETED' || status === 'INVITED')) {
    return '#eeeeee';
  }

  if (['ADMIN', 'HR', 'BOARD', 'FINANCE'].includes(role)) {
    return '#ffb642'; // nterra secondary does not work, hardcode (?)
  }

  if (role === 'DEFAULT') {
    return 'lightgrey';
  }

  return 'primary';
};

export default UserOverview;
