import React, { FC } from 'react';
import DeveloperOverview from '../landing_page/DeveloperOverview';
import { Container, Typography } from '@mui/material';

const InfoPage: FC = () => {
  return (
    <Container maxWidth="lg" sx={{ padding: '2rem' }}>
      <Typography
        variant="h4"
        component="h2"
        gutterBottom
        sx={{
          fontWeight: 'bold',
          marginBottom: '2rem',
          marginTop: '2rem',
          fontSize: { xs: '1.75rem', sm: '2rem', md: '2.5rem' }, // Responsive font sizes
        }}
      >
        Sapling
      </Typography>
      <Typography
        variant="body1"
        sx={{
          fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' }, // Responsive font sizes
          marginBottom: '1rem',
        }}
      >
        Sapling ist ein internes Tool zur Arbeitszeiterfassung von
        Werkstudierenden. Es bietet ein smartes Timesheet, welches Eingabedaten
        automatisch validiert, die Eingabe mit automatischer Vervollständigung
        erleichtert und eingetragene Daten direkt kategorisiert. Arbeitszeiten
        werden für beliebige Zeiträume automatisch akkumuliert und können
        seitens HR mit einem Klick abgerechnet werden. Für vergangene
        Abrechnungen wird eine Historie mit Übersicht und Detailansicht
        bereitgestellt. Mittels einer Benutzerverwaltung können
        Arbeitszeitregelungen pro Nutzer angepasst werden.
      </Typography>

      <Typography
        variant="h4"
        component="h2"
        gutterBottom
        sx={{
          fontWeight: 'bold',
          marginBottom: '2rem',
          marginTop: '2rem',
          fontSize: { xs: '1.75rem', sm: '2rem', md: '2.5rem' }, // Responsive font sizes
        }}
      >
        Namensgebung
      </Typography>
      <Typography
        variant="body1"
        sx={{
          fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' }, // Responsive font sizes
          marginBottom: '1rem',
        }}
      >
        Da wir ähnliche Aufgaben übernehmen wie ein anderes Zeiterfassungstool
        mit landwirtschaftlich konnotiertem Namen, haben wir nach einem Namen
        gesucht, welcher darauf anspielt. Unser interner Projektname lautete
        Sichel, aber scharfe Werkzeuge wirken jetzt nicht gerade verspielt oder
        hip, daher haben wir das trotz des unfassbar hohen Meme-Potenzials
        verworfen. Ein Setzling (auf englisch: Sapling) ist dabei eine Vorstufe
        eines Baumes, wodurch die ursprüngliche Zielgruppe der Werkstudierenden
        hervorgehoben wird. Durch die Existenz von Baumfrüchten ist zusätzlich
        die Verbindung zur Ernte gegeben. Intuitiv, oder?
      </Typography>

      <Typography
        variant="h4"
        component="h2"
        gutterBottom
        sx={{
          fontWeight: 'bold',
          marginBottom: '2rem',
          marginTop: '2rem',
          fontSize: { xs: '1.75rem', sm: '2rem', md: '2.5rem' }, // Responsive font sizes
        }}
      >
        Projektdaten
      </Typography>
      <Typography
        variant="body1"
        component={'span'}
        sx={{
          fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' }, // Responsive font sizes
          marginBottom: '1rem',
        }}
      >
        <ul>
          <li>Projektstart: 25.07.2024</li>
          <li>Initial Release: 07.10.2024</li>
          <li>Entwickler: 2 Werkstudenten</li>
          <li>
            Vision: Das perfekte Zeiterfassungstool für Werkstudierende bei
            nterra zu bauen
          </li>
          <li>507 commits</li>
          <li>101.262 Files, 9.143 Folders</li>
        </ul>
      </Typography>

      <DeveloperOverview />
    </Container>
  );
};

export default InfoPage;
