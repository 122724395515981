import React, { FC, useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  MenuItem,
  Button,
  Box,
  Select,
  SelectChangeEvent,
  FormControl,
  InputLabel,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { useWindowWidth } from '../../contexts/WindowWidthContext';
import { useNavigate } from 'react-router-dom';
import { AccountingService, Lastbalance } from '../../api';

const HrPrompt: FC = () => {
  const { isType } = useWindowWidth();
  const navigate = useNavigate();

  const [lastbalances, setLastbalances] = useState<Lastbalance[]>([]);
  const [selectedBalance, setSelectedBalance] = useState<Lastbalance | null>(
    null,
  );
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);

  useEffect(() => {
    // TODO: Do this for all groups, lol?
    AccountingService.getLastBalanced('WERKSTUD').then((x) => {
      setLastbalances(x);

      if (x.length > 0) setSelectedBalance(x[0]);
    });
  }, []);

  useEffect(() => {
    if (selectedBalance) {
      setStartDate(dayjs(selectedBalance.firstRelevantDate));
      setEndDate(
        dayjs(selectedBalance.firstRelevantDate)
          .add(1, 'month')
          .subtract(1, 'day'),
      );
    }
  }, [selectedBalance]);

  const handleClick = () => {
    const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
    const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');
    navigate(
      `/hroverview/${formattedStartDate}/${formattedEndDate}/${selectedBalance?.grouprole}`,
    );
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value;
    const selected =
      lastbalances.find((balance) => balance.grouprole === selectedValue) ||
      null;
    setSelectedBalance(selected);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="80vh"
      px={2}
    >
      <Card
        sx={{
          maxWidth: 600,
          width: '100%',
          p: 3,
          boxShadow: 3,
          borderRadius: '12px',
        }}
      >
        <CardContent>
          <Typography variant="h4" component="div" gutterBottom align="center">
            Abrechnen
          </Typography>

          <Box my={3}>
            <FormControl fullWidth>
              <InputLabel id="group-select-label">Abrechnungsgruppe</InputLabel>
              <Select
                labelId="group-select-label"
                label="Abrechnungsgruppe"
                id="group-select"
                fullWidth
                value={selectedBalance?.grouprole || ''}
                onChange={handleSelectChange}
                variant="outlined"
              >
                {lastbalances.map((balance) => (
                  <MenuItem
                    key={balance.grouprole} // Ensure unique key for each MenuItem
                    value={balance.grouprole} // Use `grouprole` as value
                  >
                    {balance.grouprole}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          {isType('desktop') ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mb={3}
            >
              <DatePicker
                label="Startdatum (inkl.)"
                value={startDate}
                format="DD.MM.YYYY"
                onChange={(date) => setStartDate(date)}
              />
              <Typography mx={2}>-</Typography>
              <DatePicker
                format="DD.MM.YYYY"
                label="Enddatum (inkl.)"
                value={endDate}
                onChange={(date) => setEndDate(date)}
              />
            </Box>
          ) : (
            <>
              <Box my={3}>
                <DatePicker
                  sx={{ width: '100%' }}
                  label="Startdatum (inkl.)"
                  format="DD.MM.YYYY"
                  value={startDate}
                  onChange={(date) => setStartDate(date)}
                />
              </Box>
              <Box my={3}>
                <DatePicker
                  sx={{ width: '100%' }}
                  label="Enddatum (inkl.)"
                  format="DD.MM.YYYY"
                  value={endDate}
                  onChange={(date) => setEndDate(date)}
                />
              </Box>
            </>
          )}

          <Button
            variant="contained"
            fullWidth
            style={{ height: '50px', fontSize: '18px', fontWeight: 'bold' }}
            onClick={handleClick}
          >
            JUST DO IT
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default HrPrompt;
